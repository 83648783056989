@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  background-color: #C4D4DF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar{
  background: linear-gradient(45deg, #0094ff, #003261);
  border-radius: 15px;
  margin: 6px 13px 0px 13px;
  
}
/* .nav-link{
  color: #fff !important;
  font-weight: bold;
} */
.navbar-brand{
  color: #fff !important;
  font-weight: bold;
  font-size: 1.2rem !important;
}
.navbar-nav{
  margin-left: auto !important;
}

.smalling{
  height: 150px;
  width: 150px !important;

}

.doc_det{
  font-size: 0.9rem !important;
  
  
}
.titel{
  font-size: 1.5rem !important;
  font-weight: bold;

}
.box_shadow{
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  box-shadow: rgba(230, 255, 37, 0.924) 0px 5px, rgba(230, 255, 37, 0.3) 0px 10px, rgba(230, 255, 37, 0.2) 0px 15px, rgba(230, 255, 37, 0.1) 0px 20px, rgba(230, 255, 37, 0.05) 0px 25px;
  margin-top: 20px !important;
  border-radius: 15px !important;
}
.box_shadow_2{
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  /* box-shadow: rgba(230, 255, 37, 0.924) 0px 5px, rgba(230, 255, 37, 0.3) 0px 10px, rgba(230, 255, 37, 0.2) 0px 15px, rgba(230, 255, 37, 0.1) 0px 20px, rgba(230, 255, 37, 0.05) 0px 25px; */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  margin-top: 20px !important;
  border-radius: 10px !important;
}
.box_shadow_3{
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  /* box-shadow: rgba(230, 255, 37, 0.924) 0px 5px, rgba(230, 255, 37, 0.3) 0px 10px, rgba(230, 255, 37, 0.2) 0px 15px, rgba(230, 255, 37, 0.1) 0px 20px, rgba(230, 255, 37, 0.05) 0px 25px; */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

}
.box_shadow_4{
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
  /* box-shadow: rgba(230, 255, 37, 0.924) 0px 5px, rgba(230, 255, 37, 0.3) 0px 10px, rgba(230, 255, 37, 0.2) 0px 15px, rgba(230, 255, 37, 0.1) 0px 20px, rgba(230, 255, 37, 0.05) 0px 25px; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 20px, rgba(0, 0, 0, 0.15) 0px 6px 6px;

}
.smalling_2{
  height: 200px;
  width: 70% !important;
}
.detail_name{
  font-size: 1.2rem !important;
  font-weight: bold;
}
.spinner-parent{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.704);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

/* antd override */
.customer_input{
  border-radius: 12px !important;
  border: 1px solid #4395d1 !important;
  height: 35px !important;
  width: 70% !important;
  background-color: #f0f0f0;
  transition: background-color 0.3s;

}
.customer_input:focus {
  background-color: #ffffff; /* Background color when focused */
}
input{
  border-radius: 12px !important;
  /* border: 1px solid #4395d1 !important; */
  height: 25px !important;
  
}
input:focus , .ant-picker{
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid gray !important;
}
label{
  font-size: 16px !important;
  color: black !important;
  font-weight: bold;
}
.ant-form-item{
  margin: 10px 0 !important;; 
}
.ant-picker-input input{
  border: none !important;
}
.ant-picker{
  border-radius: 2px !important;
  border: 1px solid rgba(128, 128, 128, 0.521) !important;
  height: 40px !important;
}

.custom-table .ant-table-tbody > tr > td {
  border-right: 1px solid #e8e8e8; /* Add a light grey right border */
}

.custom-table .ant-table-tbody > tr > td:last-child {
  border-right: none; /* Remove the right border from the last cell */
}

.custom-table .ant-table-thead > tr > th {
  font-weight: bold;
  background-color: #f0f0f0; /* Optional: Change header background color */
  border-right: 1px solid #e8e8e8; /* Add a light grey right border to headers */
}

.custom-table .ant-table-thead > tr > th:last-child {
  border-right: none; /* Remove the right border from the last header cell */
}

/* Button and icon styles */
.custom-table .ant-btn-primary {
  display: flex;
  align-items: center;
  color: #fff;
  background-color: lightgreen;
  border-color: lightgreen;
}

.custom-table .ant-btn-danger {

  color: #fff;
  /* background-color: red; */
  border-color: red;
}

.custom-table .ant-btn-primary:hover {
  background-color: #1890ff; /* Change hover color for primary button */
  border-color: #1890ff;
}

.custom-table .ant-btn-danger:hover {
  background-color: #ff4d4f; /* Change hover color for danger button */
  border-color: #ff4d4f;
}

/* Custom icon color */
.custom-table .anticon-schedule {
  color: lightgreen; /* Light green color for appoint icon */
}

.custom-table .anticon-delete {
  color: red; /* Red color for delete icon */
}

/* Ensure text and icon color remain white on hover */
.custom-table .ant-btn-primary:hover .anticon-schedule,
.custom-table .ant-btn-danger:hover .anticon-delete {
  color: white; /* Change icon color to white on hover */
}

/* Common styling */

.page-title{
  font-size: 25px;
  color: rgba(0, 0, 0, 0.833);
  font-weight: bold;
}
.card-title{
  font-size: 22px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.627);

}
.normal-text{
  font-size: 1rem;
}
.card{
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  border-radius: 0 !important;
  background-color: #fff;
}
.search_input{
  border-radius: 12px !important;
  border: 1px solid #4395d1 !important;
  height: 35px !important;
  width:40% !important;
  background-color: #f0f0f0;
  transition: background-color 0.3s;
}
.primary-button{
  background: #11365a;
  border-color: rgba(128, 128, 128, 0.521) !important;
  height: 40px !important;
  width: 100% !important;
  color: white !important;
  font-weight: bold;
  font-size: 16px !important;
  width: max-content !important;
  transition: 0.5s;
}
.primary-button:hover {
  color: black !important;
  background:  #4395d1 !important;
}
.primary-button_logout{
  background: #11365a;
  border-color: rgba(128, 128, 128, 0.521) !important;
  height: 35px !important;
  padding: 5px;
  width: 80% !important;
  color: white !important;
  font-weight: bold;
  font-size: 16px !important;
  width: max-content !important;
  transition: 0.5s;
}
.primary-button_logout:hover {
  color: black !important;
  background:  #4395d1 !important;
}
.full-width-button{
  width: 100% !important;
}
p{
  color: rgba(0, 0, 0, 0.673) !important;
  padding: 0 !important;
  margin: 0 !important;
}

.anchor{
  color: #000 !important;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  font-size: 16px !important;
}
.anchor:hover{
  text-decoration: underline !important;
}

.cursor-pointer{
  cursor: pointer;
}

/* authentication pages */

.authentication{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(45deg, #0094ff, #003261);
}
.authentication_form{
 width: 400px;
 border-bottom-right-radius: 30px !important;

}

.authentication .card-title{
 font-size: 1.2rem;
 font-weight: bold;
 background: #4395d1;
 border: 1px solid rgba(128, 128, 128, 0.521) !important;

 color: black !important;
 max-width: max-content !important;
 padding: 7px 15px;
 margin-left: -40px;
 border-bottom-left-radius: 10px;
}

/* leaflet container */

.leaflet-container {
  height: 100vh;
  width: 100%;
}
.tabs_titel{
  font-weight: bold;
  
}
.landing{
  /* background-color: #fff200; */
   background:linear-gradient(to right, rgba(0, 0, 0, 0), rgb(0,133,230));
  height: 100vh;
  font-weight: bold;
  /* font-size: 3.5rem; */
  padding-top: 20px;
  width: 99.7vw;
 
  align-items: center;
  justify-content: center;
  color: #fff;
}
.hero_img{
 
  
    animation: bounceAnimation 0.6s infinite alternate;
  
  

}
.search_input {
  background-color: #f0f0f0; /* Default background color */
  transition: background-color 0.3s; /* Smooth transition */
  padding-left: 10px; /* Adjust padding if needed */
  border-radius: 4px; /* Optional: Add border-radius for rounded corners */
  border: none; /* Remove the default border */
}

.search_input:focus {
  background-color: #ffffff; /* Background color when focused */
  outline: none; /* Remove the outline on focus */
  box-shadow: 0 0 0 2px #1890ff; /* Optional: Add a shadow on focus for better UX */
}

.search_icon {
  color: #8c8c8c; /* Icon color */
  margin-left: 0px; /* Optional: Adjust icon position */
}
.logoimage{
  height: 5% !important;
}
@keyframes bounceAnimation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}


footer {
  padding-bottom: 30px !important;
  /* background: linear-gradient(45deg, #0094ff, #003261); */

}
footer hr {
  margin-bottom: 30px;
}
footer .content {
  display: flex;
  gap: 20px;
  
}
footer .content div {
  flex: 2.5;
}
footer .content div:nth-child(3) {
  flex: 1;
}
footer .content div h4 {
  font-weight: 900;
  margin-bottom: 20px;
  color: white;
}
footer .content div ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 0px !important;


}
footer .content div ul a {
  color: white;
  text-decoration: none;
}
footer .content div ul a:hover {
  color: #003A6E;

  transition: 0.3s;
}
footer .content div ul li span {
  color: gray;
  
}
footer .content div ul li span:first-child {
  width: 150px;
  display: inline-block;
}

footer .content div:last-child div {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;
}
footer .content div:last-child div svg {
  font-size: 24px;
}
@media (max-width: 1135px) {
  footer .content {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  footer .content div,
  footer .content div:nth-child(3) {
    flex: none;
    width: 340px;
    margin-bottom: 30px;
  }
}
@media (max-width: 900px) {
  footer {
    padding-bottom: 0 !important;
  }
  footer .content div,
  footer .content div:nth-child(3) {
    width: 100%;
  }
}
@media (max-width: 390px) {
  footer .content div:nth-child(3) ul li {
    display: flex;
    flex-direction: column;
  }
}

.dashboard {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.dashboard .banner:first-child {
  height: 35vh;
  display: flex;
  gap: 5px;
}
.dashboard .banner:first-child .firstBox {
  flex: 2;
  display: flex;
  align-items: center;
  border-radius: 20px;
  margin: 13px 13px;
  background:  linear-gradient(45deg, #0094ff, #003261);
  padding: 20px 20px 0 10px;
  
}
.dashboard .banner:first-child .firstBox img {
  height: 100%;
  flex: 1;
}
.dashboard .banner:first-child .firstBox .content {
  flex: 2;
}
.dashboard .banner:first-child .firstBox .content div {
  display: flex;
  align-items: center;
  font-size: 34px;
  margin-bottom: 12px;
}
.dashboard .banner:first-child .firstBox .content div p {
  margin-right: 10px;
  font-size: 34px;
}
.dashboard .banner:first-child .firstBox .content div h5 {
  color: #ff008d;
}
.dashboard .banner:first-child .firstBox .content p {
  font-size: 16px;
}
.dashboard .banner:first-child .secondBox {
  background: #0094ff;
  color: #fff;
  margin: 13px 13px;
}
.dashboard .banner:first-child .thirdBox {
  color: #ff008d;
  background: #fff;
  margin: 13px 13px;
}
.dashboard .banner:first-child .secondBox,
.dashboard .banner:first-child .thirdBox {
  flex: 1;
  border-radius: 20px;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
}
.dashboard .banner:first-child .secondBox p,
.dashboard .banner:first-child .thirdBox p {
  font-size: 24px;
  font-weight: 600;
}
.dashboard .banner:first-child .secondBox h3,
.dashboard .banner:first-child .thirdBox h3 {
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 2px;
}
.dashboard .banner:last-child {
  height: 65vh;
  background: #fff;
  border-radius: 20px;
  padding: 40px;
  margin: 13px 13px;
}
.dashboard .banner:last-child h5 {
  font-size: 24px;
  letter-spacing: 2px;
  margin-bottom: 20px;
  color: #111;
}
.dashboard .banner table {
  width: 100%;
  color: #111;
  font-size: 20px;
}
.dashboard .banner table select {
  font-size: 20px;
  border: none;
  width: 100%;
  font-weight: 600;
}
.dashboard table td svg{
  display: flex;
  margin: 0 auto;
}
.dashboard table td .green{
  font-size: 20px;
  color: #16a34a;
}
.dashboard table td .red{
  font-size: 20px;
  color: #dc2626;
}
.dashboard .banner table select:focus {
  outline: none;
}
.dashboard .banner table .value-pending {
  color: #eab308;
}
.dashboard .banner table .value-accepted {
  color: #16a34a;
}
.dashboard .banner table .value-rejected {
  color: #dc2626;
}
.dashboard .banner table thead {
  text-align: justify;
  padding: 12px 0;
}
.dashboard .banner table thead th {
  padding: 12px 0;
}
.dashboard .banner table tbody td {
  padding: 12px 0;
}
.head_txt{
  font-size: 1.5rem;
  
  color: #fff !important;

}
@media (max-width: 1376px) {
  .dashboard .banner:first-child .firstBox .content div,
  .dashboard .banner:first-child .firstBox .content div p {
    font-size: 26px;
  }
  .dashboard .banner:first-child .firstBox .content p {
    font-size: 14px;
  }
  .dashboard .banner:first-child .secondBox p,
  .dashboard .banner:first-child .thirdBox p,
  .dashboard .banner:first-child .secondBox h3,
  .dashboard .banner:first-child .thirdBox h3 {
    font-size: 20px;
  }
}
@media (max-width: 1020px) {
  .dashboard {
    height: fit-content;
  }
  .dashboard .banner:first-child,
  .dashboard .banner:last-child {
    flex-wrap: wrap;
    height: fit-content;
  }
  .dashboard .banner:first-child .firstBox {
    flex: none;
    width: 100%;
    height: 265px;
  }
  .dashboard .banner:first-child .secondBox,
  .dashboard .banner:first-child .thirdBox {
    height: 175px;
  }
}
@media (max-width: 620px) {
  .dashboard .banner:first-child .secondBox,
  .dashboard .banner:first-child .thirdBox {
    width: 100%;
    flex: none;
  }
}
@media (max-width: 485px) {
  .dashboard .banner:first-child .firstBox {
    flex-direction: column;
    height: fit-content;
    padding: 20px;
  }
  .dashboard .banner:first-child .firstBox img {
    width: 270px;
    height: 270px;
    margin-bottom: 25px;
  }
  .dashboard .banner:first-child .firstBox div {
    justify-content: center;
  }
}
